import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A beágazott `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` méretét a beágyazó oldal dinamikusan módosíthatja.`}</p>
    <p>{`A szélesség változtatására a beágyazott modul reagál és 400 pixel szélesség fölött végig minden funkciója elérhető.
Ajánlott legalább 430px szélességet használni.`}</p>
    <p>{`Ha a tartalom függőlegesen nem fér el, vertikális görgetősáv jelenik meg.`}</p>
    <h2>{`Dinamikus magasságállítás`}</h2>
    <p>{`Mivel `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` használatakor a beágyazó és a beágyazott oldalakat egymástól függetlenül kezelik a böngészők, a beágyazó
oldal csak az iframe elemet érzékeli és nem tud dinamikusan annak tartalmához igazodni.`}</p>
    <p>{`Hogy ezt elérjük, a modul minden alkalommal amikor annak tartalma változik egy speciális üzenetet küld a beágyazó
oldalnak `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage"
      }}>{`postMessage()`}</a>{` segítségével. Ezt a
bágyazó oldal feldolgozhatja és és átállíthatja az `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` magasságát.`}</p>
    <p>{`Az alábbi Javascript példakód segíthet ennek implementálásában:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`(function (iframeSelector, expectedOrigin) {
    function receiveMessage(event) {
        var iframe = document.querySelector(iframeSelector);
        if (!iframe) {
            console.error('iframe not found.');
            return;
        }

        if (event.origin !== expectedOrigin) {
            console.info(
                'Unexpected message received origin: ' + event.origin +
                ' (expected: ' + expectedOrigin + ')'
            );
            return;
        }

        var newHeight = Number(event.data);
        if (!newHeight || newHeight < 100 || newHeight > 1200) {
            console.error('Invalid height value received!');
            return;
        }

        iframe.setAttribute('height', newHeight);
        console.info('iframe resized.');
    }
    window.addEventListener('message', receiveMessage, false);
})(
    '#pentech-quickrisk',  // iframe selector
    'https://quickrisk.module.demo.pentech.hu'  // expected origin of messages
);
`}</code></pre>
    <h3>{`Kompatibilitás`}</h3>
    <p>{`IE10+ kompatibilis kódrészlet.`}<br parentName="p"></br>{`
`}{`IE8 kompatibilitáshoz a `}<inlineCode parentName="p">{`console`}</inlineCode>{` használatát kell csak törölni vagy átalakítani és IE8 által támogatott selector-t
használni.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      